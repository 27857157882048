<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-sheet
          color="white"
          class="mx-auto"
          style="max-width: 100%; box-shadow: rgba(47, 60, 74, 0.01) 0px 8px 32px, rgba(47, 60, 74, 0.02) 0px 8px 16px;"
        >
          <v-card-text class="text-h5">
            {{ list.length }} Demandas
          </v-card-text>
        </v-sheet>
      </v-col>
      <v-col
        v-for="(item, index) in list"
        :key="'demand' + item.id"
        class="col-lg-12 col-12"
      >
        <v-sheet
          color="white"
          class="mx-auto"
          style="max-width: 100%; box-shadow: rgba(47, 60, 74, 0.01) 0px 8px 32px, rgba(47, 60, 74, 0.02) 0px 8px 16px;"
        >
          <v-card-text>
            <div
              class="d-flex justify-space-between align-center flex-wrap m-3"
            >
              <div class="flex-1 d-flex  align-center mr-4">
                <v-avatar color="primary" class="mr-2">
                  <h3 class="text-h5 white--text">{{ index + 1 }}</h3>
                </v-avatar>
                <div class="flex-grow-1">
                  <p class="font-weight-semi ma-0" style="color: black">
                    {{ item.rank.name }}
                  </p>
                </div>
              </div>
              <div class="flex-1 d-flex  align-center mr-4">
                <v-icon class="mr-2">
                  fas fa-calendar-alt
                </v-icon>
                {{ item.deadline | formatDate }}
              </div>
              <div class="flex-1 d-flex  align-center mr-4">
                <v-chip color="success" outlined>
                  {{ item.status.name }}
                </v-chip>
              </div>
            </div>

            <v-divider class="mt-5 mb-3"></v-divider>

            <div class="d-flex justify-space-between align-center flex-wrap">
              <small class="text--disavled">
                Criado em {{ item.created_at | formatDate }}
              </small>
              <div>
                <v-btn :to="`/app/demandas/${item.id}`" small>
                  Ver Mais
                </v-btn>
              </div>
            </div>
          </v-card-text>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";
export default {
  props: {
    list: {
      type: Array,
      default: function() {
        return [];
      },
      required: false,
    },
  },

  filters: {
    formatDate: function(el) {
      return moment(el).format("DD/MM/YYYY HH:mm");
    },
  },
};
</script>

<style></style>
