<template>
  <v-container class="pt-6" fluid>
    <div>
      <v-sheet class="px-6 py-5 ">
        <v-container>
          <div class="d-flex flex-wrap justify-space-between mb-8">
            <div class="flex: 1">
              <div class="d-flex align-start justify-space-between">
                <div class="mb-3">
                  <h5 class="text-h5 mr-2 mb-0">{{ data.name }}</h5>
                  <p class="text--disabled mb-0">
                    {{ data.email }}
                  </p>
                </div>

                <div>
                  <v-chip
                    outlined
                    small
                    v-if="data.role"
                    color="green"
                    green-text
                    class="mt-1 mr-1"
                  >
                    {{ data.role.slug }}
                  </v-chip>
                  <v-chip
                    outlined
                    small
                    color="primary"
                    primary-text
                    class="mt-1 mr-1"
                    v-if="data.role"
                  >
                    {{ data.role.type | typeToPortuguese }}
                  </v-chip>

                  <v-chip
                    outlined
                    small
                    color="blue"
                    primary-text
                    class="mt-1 mr-1"
                    v-if="data.customer"
                  >
                    {{ data.customer.name }}
                  </v-chip>
                </div>
              </div>
            </div>
          </div>
        </v-container>
      </v-sheet>

      <lista-demandas :list="data.demands" />
    </div>
  </v-container>
</template>

<script>
import UserService from "@/services/User/UserService";
import ToastService from "@/services/ToastService";
import ListaDemandas from "../../components/Listas/ListaDemandas.vue";
export default {
  components: {
    ListaDemandas,
  },
  data: () => ({
    loading: false,

    data: {},
    UserService: new UserService(""),
  }),

  filters: {
    typeToPortuguese(value) {
      return value == "responsible" ? "Funcionario" : "Cliente";
    },
  },

  mounted: async function() {
    const id = this.$route.params.id;

    this.loading = true;
    await this.showData(id);
  },

  methods: {
    async showData(id) {
      try {
        this.data = await this.UserService.show("users/" + id);
      } catch (exception) {
        ToastService("Não foi possível consultar os dados.", "error");

        this.cancel();
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style></style>
